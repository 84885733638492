import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../layouts/Layout"
import PageFooter from "../components/PageFooter"
import SiteMetadata from "../components/SiteMetadata"

const AboutPage = ({ data }) => {
  const downloadFiles = data.downloadFiles.nodes || []
  return (
    <Layout>
      <SiteMetadata
        title="Pri Bellafronte - Designer de marcas e logos | Downloads"
        description="Página onde disponibilizo downloads de materiais diversos ligados à design gráfico"
      />

      <div>
        <div className="container py-12 lg:pb-16">
          <div className="flex flex-wrap">
            <div className="w-full md:w-2/3 m-auto pb-8 md:pb-0">
              <h1 className="text-3xl text-center leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Downloads
              </h1>

              <div className="mt-4 leading-loose">
                Olá! Nesta página vou compartilhar minha coleção de materiais de
                design gráfico de alta qualidade. Os materiais são gratuitos e
                podem ser usados da forma que você achar melhor. Qualquer
                dúvida, é so entrar em contato.
              </div>

              {downloadFiles.length ? (
                downloadFiles.map(downloadFile => {
                  return (
                    <DownloadItemCard
                      imageData={downloadFile.image.gatsbyImageData}
                      description={downloadFile.description.description}
                      downloadLink={downloadFile.downloadLink}
                    />
                  )
                })
              ) : (
                <p className="mt-8 font-bold">
                  Ops! Ainda não tem nada por aqui. Logo logo colocaremos alguns
                  arquivos legais para vocês baixarem.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="py-12 px-4 lg:py-16">
        <PageFooter />
      </div>
    </Layout>
  )
}

const DownloadItemCard = ({ imageData, description, downloadLink }) => {
  return (
    <div className="flex items-center justify-center m-4 rounded-md shadow-lg border-2">
      <div className="w-1/3 h-full overflow-hidden rounded-l-md">
        <GatsbyImage
          className="w-full h-full object-cover h-48"
          image={imageData}
          alt={description}
        />
      </div>
      <div className="w-2/3 px-4 py-2">
        <h2 className="text-xl font-medium mb-2">{description}</h2>
        <a
          href={downloadLink}
          className={
            "mt-4 text-center w-full md:w-auto inline-block text-lg sm:text-lg font-medium " +
            "border-2 p-3 border-transparent font-color-off-white hover:bg-gray-700 rounded-md " +
            "hover:border-gray-800 hover:border-2 transition duration-150 ease-in-out site-color-black font-bold"
          }
          activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
        >
          Download
        </a>
      </div>
    </div>
  )

  // return (
  //   <div className="bg-white shadow-lg rounded-lg overflow-hidden">
  //     <GatsbyImage className="w-full" image={imageData} alt="Card" />
  //     <div className="p-4">
  //       <p className="text-gray-900 font-medium text-lg mb-2">{description}</p>
  //       <a href={downloadLink} className="text-gray-900 font-medium text-sm">
  //         {downloadLink}
  //       </a>
  //     </div>
  //   </div>
  // )
}

export default AboutPage

export const query = graphql`
  {
    downloadFiles: allContentfulDownloadItem(
      filter: { node_locale: { eq: "pt-BR" } }
    ) {
      nodes {
        name
        id
        downloadLink
        description {
          description
        }
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`
